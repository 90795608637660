.bg-products {
    background-size: cover !important; 
    background-image: url(../../images/bg-products.svg) !important;
}

.sidebar-btn {
    width: 100% !important;
}

.toggle-buttons-hover:hover {
    border: 1px solid var(--bs-gray-500) !important;
}

.hide {
    background-color: var(--bs-gray-100) !important;
}

.list-card-bar {
    width: 50% !important;
    height: 100% !important;
}

.btn-hover:hover {
    background-color: var(--bs-black) !important;
    color: var(--bs-white) !important;
    border: none !important;
}

.bg-product {
    background-size: cover !important; 
    background-image: url(../../images/bg-product-card.svg) !important;
}

.bg-danger {
    background-color: var(--bs-red) !important;
}

.bg-archived {
    background-color: var(--bs-orange) !important;
}

.bg-orange {
    background-color: var(--bs-orange) !important;
}

.text-orange {
    color: var(--bs-orange) !important;
}

.bg-yellow {
    background-color: var(--bs-yellow) !important;
}

.bg-green {
    background-color: #00AD5F !important;
}

.text-green {
    color: var(--bs-green) !important;
}

.card-info {
    width: 50% !important;
}

.product-card {
    height: 100% !important;
}

.accordion-button {
    background-color: transparent !important;
}

.accordion-item {
    background-color: transparent !important;
}

.accordion {
    background-color: transparent !important;
    border-top: solid thin black !important;
    --bs-accordion-border-color: transparent !important;
}

.accordion-button:focus {
    outline: 0 !important;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
  }

.accordion-button::after {
    background-image: url(../../images/plus.svg) !important;
}

.accordion-button:not(.collapsed)::after {
    background-image: url(../../images/minus.svg) !important;
}

.custom-accordion-header .accordion-button {
    padding: 0;
    padding-top: 15px !important;
}

.custom-accordion-header .accordion-button::after {
    background-image: url(../../images/accordion-arrow-white-up.svg) !important;
}
  
.custom-accordion-header .accordion-button.collapsed::after {
    background-image: url(../../images/accordion-arrow-white-up.svg) !important;
}

.bg-dark {
    background-color: var(--bs-primary) !important; 
}

.modal-img {
    max-width:100%;
    max-height:100%; 
}

.horizontal-line {
    height: 40%;
    border-bottom: 1px solid var(--bs-primary);
}

.css-1jqq78o-placeholder {
    color: rgb(105, 105, 105) !important; /* Changing color of sidebar filter titles*/
}