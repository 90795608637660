.btn-footer {
  background-color: transparent !important;
  color: var(--bs-white);
}

.bg-footer {
  background-color: var(--bs-primary) !important;
}

.link {
  color: inherit;
  text-decoration: none;
}

.link > h6, .link > p {
  font-size: 1.18rem;
  font-family: var(--bs-btn-font-family);
  font-weight: 400;
  letter-spacing: 1px;
  margin-bottom: .8rem;
  &:hover {
    color: var(--bs-yellow);
  }
}

.link_header {
  font-size: 1.15rem;
  font-family: var(--bs-btn-font-family);
  line-height: 1.5rem;
  letter-spacing: .5px;
}
