.card-1 {
    height: 100% !important;
}

.align-flex {
    flex-wrap: wrap;
    align-content: center;
}

.card-spacing {
    margin-left: 100px;
    padding-top: 10%;
    padding-bottom: 10%;
}

.border-transparent {
    border-bottom: var(--bs-gray-400) solid thin !important;
}

.main-card {
    background: transparent !important;
    --bs-border-opacity: 0.83;
    border: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) solid thin !important;
}

.list-group-item:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important
}

@media (max-width: 768px) {
    #homepage-header {
        justify-content: center !important;
    }
}