#accordion-custom.accordion {
  border: none !important;
}

#accordion-header .accordion-button::after {
  margin-left: 5px;
  background-size: 0.8rem;
  width: 0.8rem;
  height: 0.8rem;
}

#accordion-header .accordion-button.collapsed, #accordion-header .accordion-button:not(.collapsed) {
  font-family: var(--bs-font-sans-serif);
  font-size: 0.8rem;
  padding: 0;
  margin-right: 0.8rem;
}

#accordion-body-custom.accordion-body {
  padding: 0;
}

.tq_container {
  flex-direction: column;
  flex-basis: max-content;
  flex-wrap: wrap;
}

.tq_Link {
  text-decoration: none;
  width: 200px;
}

.tq_Link:hover {
  color: var(--bs-indigo);
}
