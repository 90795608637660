.col-cards {
  height: 387.59px;
  width: 320px !important;
  margin-right: 10px;
  position: relative;
}

.row-learn-more {
  width: 100%;
  position: absolute;
  bottom: 0;
  justify-content: flex-end;
  padding-right: 20px;
  padding-bottom: 5px;
}

.col-cards:hover {
  background-color: var(--bs-primary) !important;
  color: var(--bs-white) !important;
}

.clickable {
  cursor: pointer;
}