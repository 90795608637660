h1, h2, h3, h4, h5, h6 {
    font-family: 'Helvetica', sans-serif;
}

p, button {
    font-family: 'Helvetica', sans-serif;
}

table tbody tr td {
    font-size: .8em !important;
}

table tbody tr td img {
    height: 60px;
    width: auto;
}

.form-check-input[type=checkbox] {
    border-color: #373737; /* Change border color */
    border-width: 2px; /* Make border thicker */
}

@media screen and (max-width: 600px) {
  .hiddenOnSmallScreens {
    display: none;
  }
  /* Hide the rows-per-page selector */
  .MuiTablePagination-actions
  .MuiTablePagination-selectRoot,
  .MuiTablePagination-select,
  .MuiTablePagination-selectLabel,
  .MuiInputBase-root {
    display: none;
  }

  /* Hide the displayed rows text */
  .MuiTablePagination-displayedRows {
    display: none;
  }

  /* Adjust the spacing for the pagination container */
  .MuiTablePagination-toolbar {
    justify-content: center; /* Center the pagination controls */
  }

  /* Optional: Adjust the size of the buttons if necessary */
  .MuiIconButton-root {
    padding: 4px; /* Smaller padding */
    margin: 0 2px; /* Reduce margin between buttons */
  }

  .custom-responsive-class {
    width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  .w-sm-100 {
    width: 100%;
  }

  .mb-xs-3 {
    margin-bottom: 1rem !important;
  }

  .padding-xs-0 {
    padding: 0 !important;
  }

  .margin-xs-0 {
    margin: 0 !important;
  }

  .border-only-top-bottom {
    border-left: none !important;
    border-right: none !important;
  }

}

@media (min-width: 768px) {
    .border-md-left {
        border-right: 1px solid var(--bs-dark);
    }
}

.MuiTableCell-root .MuiSelect-select {
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.header {
    background-size: cover !important; 
    background-image: url(images/bg-header-2.png) !important;
    background-color: var(--bs-primary);
}

.subheader {
    background-size: cover !important; 
    background-image: url(images/bg-header-2.png) !important;
    background-color: var(--bs-primary);
}

.subheader-row {
    --bs-border-opacity: 0.83;
    border: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) solid thin !important;
    display: inline-block;
    width: min-content;
}

.subheader .container {
    display: flex;
    justify-content: center;
    padding: 3rem;
}

.header-text {
    padding: 1.5rem 5rem;
    border: var(--bs-grey-500);
    background: var(--bs-black);
    color: var(--bs-white);
}

@media (max-width: 768px) {
    .subheader {
        padding-top: 10px;
        margin-top: -10px;
    }
}

.bg-green {
    background-color: #00AD5F !important;
}

.border-warning {
    border-color: rgb(var(--bs-warning-rgb)) !important;
}

.btn {
    min-width: 100px;
}

.bg-gray {
    background-color: var(--bs-gray-200);
}

.text-bright-blue {
    color: var(--bs-blue) !important;
}

@media (max-width: 768px) {
    .btn-group .btn {
        margin-bottom: 10px;
    }
}

.remove-top-border {
    border-top: none !important;
}

.modal-backdrop {
    --bs-backdrop-zindex: 1301 !important;
}

.modal {
    --bs-modal-zindex: 1301 !important;
}

.tox-editor-header {
    z-index: 1 !important;
}

.MuiTableBody-root tr:nth-child(odd) {
    background-color: #f0f0f0;
}

.no-hover-effect:hover {
    background-color: inherit !important; /* Assuming background color change is what you're seeing. */
    /* Add any other properties you want to override on hover */
}

.MuiTableSortLabel-root, .Mui-active > .MuiTableSortLabel-icon, .MuiTableSortLabel-root:hover > .MuiTableSortLabel-icon {
    color: "DimGray" !important; /* Changing the color of filter arrow*/
    opacity: 1 !important;
    background-color: white !important;
}