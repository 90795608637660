table#fileDetails, #fileDetails th, #fileDetails td {
    border: 1px solid black;
    border-collapse: collapse;
}

table#fileDetails {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}

#fileDetails th {
    width: 33%
}