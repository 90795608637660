.alertsButton {
  border-radius: 10px !important;
  width: 10px !important;
  margin: 3px;
}

.overflow-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 100%;
   height: 3em;
   text-overflow: ellipsis; 
   overflow: hidden;
}
