@media (max-width: 575px) {
    #faq-container {
        margin: 0 !important;
    }

    #search-col {
        margin-bottom: 50px;
    }
}

@media (min-width: 1600px) {
    #faq-container {
        margin: 5 !important;
    }
}