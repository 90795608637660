.form-title {
    letter-spacing: 2px;
}

.radio-spacing {
    margin-right: 20px;
}

.form-indent-readOnly:read-only {
    margin-left: 15px;
}

.tooltip { 
    position: relative;
    z-index: 10000 !important;
}