.card.login-card {
    border: none;
    margin-top: 30px;
    margin-bottom: 30px;
}

.multifactor-table tr {
    border: 1px solid black;
}

.multifactor-table td {
    padding: 15px;
}