.link, .link:hover {
    color: inherit;
}

.btn-light {
    background-color: var(--bs-white) !important;
}

.h-card {
    max-height: 150px !important;
    object-fit: cover;
}


.homepage-card:hover {
	background-color: var(--bs-primary) !important;
	color: white !important;
	
}
