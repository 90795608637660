.login-page {
    background-image: url(../../images/bg-header-2.png);
    background-size: cover;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 127px;
}

@media (max-width: 1200px) {
    .login-page {
        top: 61px;
    }
}

.login-card {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20%;
    padding: 20px;
    text-align: center;
}

.centered-form {
    margin-left: auto;
    margin-right: auto;
}

.backup-codes {
    background: var(--bs-gray-300);
    padding: 15px
}

.login-card.success {
    padding-top: 80px;
    padding-bottom: 80px;
}

.form-control.Mui-error {
    border-color: #dc3545;
}