.white-text-nav .nav-link {
    --bs-border-opacity: 0.253;
    color: var(--bs-white) !important;
    font-family: normal normal normal 17px/20px Helvetica;
    text-transform: uppercase;
}

.active-navlink {
    border-bottom: var(--bs-warning) solid !important;
    border-width: 5px !important;
  }

.nav-line {
    border-bottom: var(--bs-white) solid thin;
}

.navbar.bg-dark {
    /* --bs-bg-opacity: 0.7; */
    background-color: var(--bs-primary) !important; 
}

.navbar.bg-dark .dropdown-menu {
    /* --bs-bg-opacity: 0.7; */
    background-color: var(--bs-primary) !important; 
}

.navbar.bg-dark .dropdown-menu .dropdown-item {
    color: var(--bs-white);
}

.navbar.bg-dark .dropdown-menu .dropdown-item:hover {
    background-color: transparent;
    color: var(--bs-warning);
}

#login-nav, #profile-nav {
    float: right;
}

@media (min-width: 1168px) {
    #login-dropdown, #profile-dropdown {
        display: none;
    }
}

@media (max-width: 1165px) {
    #login-nav, #profile-nav {
        display: none;
    }
    #hide-dropdown {
        display: none;
    }
}