#stackId {
  width: 15rem; /* Adjust the height as needed */
}

.vidLink {
  text-decoration: none;
}

.vidLink:hover {
  color: var(--bs-indigo);
}
