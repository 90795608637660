.bg-dark {
    background-color: var(--bs-primary) !important; 
}

.detail-btn {
    cursor: pointer;
}

.detail-btn:hover {
    color: var(--bs-primary);
}