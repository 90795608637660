.card-group {
    background-color: var(--bs-primary);
}

.remove-link {
    text-decoration: none;
}

.text-warning-hover {
    height: 100%;
}

.warning-hover:hover {
    border-bottom: solid thin var(--bs-warning) !important;
}

.text-warning-hover:hover {
    color: var(--bs-warning) !important;
}

