.ais-Hits-list {
    list-style: none;
}

.ais-Hits-item {
    padding: .75rem;
}

.ais-SearchBox-form {
    display: flex;
}

.ais-SearchBox-form button {
    height: 30px;
}