#contained-modal-title-vcenter {
  font-size: 2em;
}
#modalText {
  font-size: 1em;
}
#yesButton {
  background-color: green;
}

#noButton {
  background-color: red;
}
