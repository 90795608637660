button.review {
    padding: .15rem;
    max-width: 80px;
    min-width: 80px;
    max-height: 25px;
    font-size: .7em;
} 

table tbody tr td {
    font-size: .8em !important;
}

.alertsColumn p {
    font-size: .8em !important;
}

.alertsColumn button {
    padding: .15rem;
    max-width: 80px;
    min-width: 80px;
    max-height: 25px;
    font-size: .7em;
}

.alertsColumn .m-auto {
    padding: 0;
    text-align: center;
}

table tbody tr td img {
    height: 60px;
    width: auto;
}
